import './ProductStickyFooterB.scss';

import classnames from 'classnames';
import React from 'react';

import { AddToCartFormButton } from './AddToCartFormButton';
import { Col } from './Col';
import { appConfig } from './config';
import { Container } from './Container';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import { PriceBlock } from './PriceBlock';
import { ProductStickyFooterProps } from './ProductStickyFooter';
import { Row } from './Row';
import { useBreakpoint } from './useBreakpoint';
import { useWindowScrollPosition } from './useWindowScrollPosition';
import { useWindowSize } from './useWindowSize';
import { untilBreakpoint } from './util';

export const ProductStickyFooterB = ({
  className,
  fieldsHelper,
  formik,
  offsetBottom = 100,
  offsetTop = 100,
  priceHelper,
  product,
  selectedProductImage,
  selectedSku,
}: ProductStickyFooterProps) => {
  const [show, setShow] = React.useState<boolean>(false);

  const scrollPosition = useWindowScrollPosition();
  const windowSize = useWindowSize();
  const body = document.body;
  const html = document.documentElement;
  const documentHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  React.useEffect(() => {
    const scrollTopPosition = scrollPosition.position;
    const scrollBottomPosition = scrollPosition.position + windowSize.height;

    scrollTopPosition >= offsetTop && scrollBottomPosition <= documentHeight - offsetBottom
      ? setShow(true)
      : setShow(false);
  }, [documentHeight, offsetBottom, offsetTop, scrollPosition.position, windowSize.height]);

  const currentBreakpoint = useBreakpoint();

  return (
    <Div
      className={classnames('product-sticky-footer', className, {
        show: show,
      })}
      style={{ position: 'fixed', width: '100%', zIndex: 1000 }}
      uiClassName={{ bs5: 'shadow-lg' }}
    >
      <Div className="product-sticky-footer-mobile" display={{ lg: 'none' }} uiClassName={{ bs5: 'extra-small' }}>
        <Row gap={0} style={{ alignItems: 'center' }}>
          {selectedSku && (
            <Col size={12}>
              <Div paddingX={2}>
                <Row>
                  <Col size={6} uiClassName={{ bs5: 'border-end' }}>
                    <Div display={'flex'} justifyContent={'spaceBetween'} paddingY={2}>
                      <Div>{t('product.size')}</Div>
                      <Div>
                        <b>{selectedSku.name}</b>
                      </Div>
                    </Div>
                  </Col>
                  <Col size={6}>
                    <Div display={'flex'} justifyContent={'spaceBetween'} paddingY={2}>
                      <Div>{t('form.addToCartForm.quantity.label')}</Div>
                      <Div>
                        <b>{formik.values.quantity}</b>
                      </Div>
                    </Div>
                  </Col>
                </Row>
              </Div>
              <hr style={{ margin: '0' }} />
            </Col>
          )}
          <Col size={''}>
            <Div className="product-name" dynamicPadding={{ end: 2, start: 2 }} uiClassName={{ bs5: 'lh-sm' }}>
              <strong>{product.name}</strong>
            </Div>
          </Col>
          <Col size={'auto'}>
            <Row gap={2}>
              <Col style={{ alignItems: 'center', display: 'flex' }}>
                <PriceBlock hiddenDiscountPercentage priceHelper={priceHelper} />
              </Col>
              <Col size={'auto'}>
                <AddToCartFormButton
                  availabilityEmailNotificationButton={{
                    label: untilBreakpoint('xs', currentBreakpoint)
                      ? t('form.addToCartForm.buttons.availabilityEmailNotification.addShortLabel')
                      : t('form.addToCartForm.buttons.availabilityEmailNotification.addLongLabel'),
                  }}
                  fieldsHelper={fieldsHelper}
                  selectedSku={selectedSku}
                  submitButton={{
                    iconStart: { name: appConfig.icons?.cart ?? 'cart', size: 25 },
                    label: undefined,
                    onClick: formik.submitForm,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Div>
      <Div
        className="product-sticky-footer-desktop"
        display={{ lg: 'block', xs: 'none' }}
        dynamicPadding={{ vertical: 2 }}
        uiClassName={{ bs5: 'small' }}
      >
        <Container>
          <Row>
            <Col size={''}>
              <Div alignItems={'center'} display={'flex'}>
                <Div
                  className="product-image"
                  display={'inline-block'}
                  marginEnd={3}
                  style={{ overflow: 'hidden', verticalAlign: 'middle' }}
                  uiClassName={{ bs5: 'border rounded' }}
                >
                  <Image
                    aspectRatio={appConfig.productImageAspectRatio}
                    fluid
                    src={selectedProductImage?.src}
                    style={{ maxHeight: '50px' }}
                  />
                </Div>
                <Div
                  className="product-name"
                  dynamicPadding={{ end: 4 }}
                  style={{ maxWidth: '260px' }}
                  uiClassName={{ bs5: 'lh-sm' }}
                >
                  <strong>{product.name}</strong>
                </Div>
                {selectedSku && (
                  <Div className="product-info" display={'flex'}>
                    <Div
                      display={'flex'}
                      dynamicMargin={{ end: 4 }}
                      dynamicPadding={{ start: 4 }}
                      uiClassName={{ bs5: 'border-start' }}
                    >
                      <Div dynamicPadding={{ end: 3 }}>{t('product.size')}</Div>
                      <Div>
                        <b>{selectedSku.name}</b>
                      </Div>
                    </Div>
                    <Div display={'flex'} dynamicPadding={{ start: 4 }} uiClassName={{ bs5: 'border-start' }}>
                      <Div dynamicPadding={{ end: 3 }}>{t('form.addToCartForm.quantity.label')}</Div>
                      <Div>
                        <b>{formik.values.quantity}</b>
                      </Div>
                    </Div>
                  </Div>
                )}
              </Div>
            </Col>
            <Col size={'auto'} style={{ alignItems: 'center', display: 'flex' }}>
              <Div marginEnd={5}>
                <PriceBlock hiddenDiscountPercentage priceHelper={priceHelper} />
              </Div>
              <AddToCartFormButton
                fieldsHelper={fieldsHelper}
                selectedSku={selectedSku}
                submitButton={{ onClick: formik.submitForm }}
              />
            </Col>
          </Row>
        </Container>
      </Div>
    </Div>
  );
};
