import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useSessionApi } from '../api/useSessionApi';
import { useAppStaticContext } from '../AppContext';
import { Button } from '../Button';
import { useCheckoutContextStrict } from '../CheckoutContext';
import { Col } from '../Col';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { UseFormModalProps } from '../Modal';
import { Row } from '../Row';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { PasswordInput, PasswordInputProps, TextInput, TextInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { LoginFormA } from './LoginFormA';
import { LoginFormB } from './LoginFormB';

export interface LoginFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector, { forgotPasswordPagePath: string }> {
  username?: string;
}

interface FormValues {
  password: formikString;
  username: formikString;
}

type ValidationSchema = {
  password: yup.StringSchema;
  username: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  password: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  username: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const LoginFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  username,
  ...otherProps
}: LoginFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      password: getInitialString(),
      username: getInitialString(username),
      ...initialValues,
    }),
    [initialValues, username]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      password: yup.string().required(),
      username: yup.string().required().email(),
    }),
    []
  );

  const { login } = useSessionApi();

  const checkoutContext = useCheckoutContextStrict();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      login(values).then(() => {
        onSuccess && onSuccess();
        checkoutContext && checkoutContext.deleteForcedStep();
      }),
    [checkoutContext, login, onSuccess]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();

  const { getFixedPagePath } = useAppStaticContext();

  const forgotPasswordPagePath = getFixedPagePath('forgot-password');

  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('LoginForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  // variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.loginForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.loginForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          password: {
            formGroup: { label: t('form.loginForm.password.label'), names: ['password'] },
            passwordInput: {
              autoComplete: 'current-password',
              name: 'password',
              placeholder: t('form.loginForm.password.placeholder'),
            },
          },
          username: {
            formGroup: { label: t('form.loginForm.username.label'), names: ['username'] },
            textInput: {
              name: 'username',
              placeholder: t('form.loginForm.username.placeholder'),
              readOnly: username !== undefined,
              type: 'email',
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, forgotPasswordPagePath, formik, insideModal })
        ) : (
          <>
            <Row>
              <Col size={{ md: 6, xs: 12 }}>
                <FormGroup {...fieldsHelper.username.formGroup}>
                  <TextInput {...fieldsHelper.username.textInput} />
                </FormGroup>
              </Col>
              <Col size={{ md: 6, xs: 12 }}>
                <FormGroup {...fieldsHelper.password.formGroup}>
                  <PasswordInput {...fieldsHelper.password.passwordInput} />
                </FormGroup>
              </Col>
            </Row>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type LoginFormVariants = 'A' | 'B';

const LoginFormController = (props: PropsWithCustomComponentWithoutChildren<LoginFormProps>) => (
  <VariantsController<LoginFormProps, LoginFormVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: LoginFormA, B: LoginFormB },
      defaultComponent: LoginFormHelper,
      name: 'LoginForm',
    }}
  />
);
export { LoginFormController as LoginForm };

export const useLoginFormModal = (modalProps?: UseFormModalProps) => {
  const { showModal } = useModals();
  return React.useCallback(
    (formProps: LoginFormProps) => {
      showModal({
        children: <LoginFormController {...formProps} />,
        title: t('form.loginForm.modal.title'),
        ...modalProps,
      });
    },
    [modalProps, showModal]
  );
};
